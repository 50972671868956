import React from 'react';
import styled from 'styled-components';
import { Panel, Heading } from '../elements';
import AppContainer from '../components/AppContainer';

const No = styled(Heading)`
  color: ${props => props.theme.blue};
  font-size: 2rem;
  text-align: center;
`;

const NoRoute = () => (
  <AppContainer pageTitle="404: not found">
    <Panel id="content">
      <No>Page not found.</No>
    </Panel>
  </AppContainer>
);

export default NoRoute;
